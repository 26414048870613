import { useRef } from "react";
import { useNavigate, useParams } from "react-router";
// cmp
import GloginSelect from "./GloginSelect";
// contexts
import { useUserData } from "../../context/UserDataContext";
// services
import { Storage, StorageKeys } from "../../services/storage";
// types
import type { UserId } from "../../types/user";

type RouteParams = {
	userId?: UserId;
};

const SupportUserSearchCmp = () => {
	const refSearchBox = useRef<any>();
	const params = useParams<RouteParams>();

	const navigate = useNavigate();

	const { ready } = useUserData();
	const selectedSupportUserId = params.userId ?? Storage.get(StorageKeys.supportUserId);

	const clearSelectedUser = () => {
		refSearchBox.current.fetchGlogins();
		Storage.remove(StorageKeys.supportUserId);
		void navigate("/support/users");
	};

	const handleSelectedUserChanged = (value) => {
		Storage.set(StorageKeys.supportUserId, value.id);
		void navigate(`/support/users/${value.id}`);
	};

	if (!ready) {
		return null;
	}

	return (
		<GloginSelect
			ref={refSearchBox}
			userId={selectedSupportUserId}
			onSelectedUserChanged={handleSelectedUserChanged}
			onClear={clearSelectedUser}
		/>
	);
};

export default SupportUserSearchCmp;
