import { useParams } from "react-router";
import { IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
// cmp
import Svg from "../svg";
// types
import type { UserId } from "../../types/user";

type RouteParams = {
	userId: UserId;
};

const UserRightIcon = () => {
	const theme = useTheme();

	const params = useParams<RouteParams>();

	const handleOpenNewWindow = () => {
		if (params.userId) {
			window.open(
				`user_desc.html#/users/${params.userId}/general`,
				"_blank"
			);
		}
	};

	return (
		<IconButton disabled={!params.userId} onClick={handleOpenNewWindow}>
			<Svg src="navigation/openNewWindow.svg" color={params.userId ? theme.palette.primary.main : theme.palette.text.disabled} />
		</IconButton>
	);
};

export default UserRightIcon;
