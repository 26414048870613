// services
import { getGupportHttpUrlFromGupportWsUrl, getServerOption } from "./serverOption";
import { restartAccessTokenAutoRefresh } from "./accessTokenAutoRefresh";
// types
import type { OAuthData, OAuthDataWithOldTokenId } from "../types/user";
import type { SocketId } from "../types/misc";

interface RefreshTokensResult {
	expires: number;
	socketId: SocketId;
}

const REQUEST_INIT = {
	credentials: "include",
	mode: "cors",
} as const satisfies RequestInit;

const checkIsAccessTokenValid = async (): Promise<number | undefined> => {
	const params = new URLSearchParams({
		type: "iatv",
	});
	const url = `${getGupportHttpUrlFromGupportWsUrl()}?${params.toString()}` as const;
	const response = await fetch(url, REQUEST_INIT);

	if (response.ok) {
		const data = await response.json();
		if (typeof data === "object" && data !== null && "subject" in data && "displayName" in data && "expires" in data) {
			const oAuthData = data as OAuthData;
			return Number(oAuthData.expires);
		}
		throw new TypeError("OAuth data is missing", { cause: { status: response.status, data: data } });
	} else if (response.status === 401) {
		return undefined;
	} else {
		const data = await response.text();
		throw new RangeError("Invalid HTTP-Statuscode", { cause: { status: response.status, data: data } });
	}
};

const tryRefreshAccessToken = async (): Promise<RefreshTokensResult | undefined> => {
	const { channel, redirectUrl } = getServerOption();

	const params = new URLSearchParams({
		channel: channel,
		type: "rat",
		state: "nativeweb", //globalThis.crypto.randomUUID(), // TODO: currently necessary for backend
	});
	const url = `${redirectUrl}?${params.toString()}` as const;
	const init = {
		...REQUEST_INIT,
		redirect: "error",
	} as const satisfies RequestInit;
	const response = await fetch(url, init);

	if (response.ok) {
		const data = await response.json();
		if (typeof data === "object" && data !== null && "subject" in data && "displayName" in data && "expires" in data && "old_token_id" in data) {
			const oAuthData = data as OAuthDataWithOldTokenId;
			return {
				expires: Number(oAuthData.expires),
				socketId: oAuthData.old_token_id,
			} as const satisfies RefreshTokensResult;
		}
		throw new TypeError("OAuth data is missing", { cause: { status: response.status, data: data } });
	} else if (response.status === 401) {
		return undefined;
	} else {
		const data = await response.text();
		throw new RangeError("Invalid HTTP-Statuscode", { cause: { status: response.status, data: data } });
	}
};

const updateWebsocketLifetime = async (socketId: SocketId): Promise<number | undefined> => {
	const { channel } = getServerOption();

	const params = new URLSearchParams({
		channel: channel,
		type: "uwl",
		state: globalThis.crypto.randomUUID(), // TODO: currently necessary for backend
	});
	const init = {
		...REQUEST_INIT,
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({ id: socketId }),
	} as const satisfies RequestInit;
	const url = `${getGupportHttpUrlFromGupportWsUrl()}/renew?${params.toString()}` as const;
	const response = await fetch(url, init);

	if (response.ok) {
		const data = await response.json();
		if (typeof data === "object" && data !== null && "subject" in data && "displayName" in data && "expires" in data) {
			const oAuthData = data as OAuthData;
			return Number(oAuthData.expires);
		}
		throw new TypeError("OAuth data is missing", { cause: { status: response.status, data: data } });
	} else if (response.status === 410) { // socketId is not valid anymore
		return undefined;
	} else {
		const data = await response.text();
		throw new RangeError("Invalid HTTP-Statuscode", { cause: { status: response.status, data: data } });
	}
};

const invalidateOldAccessToken = async (socketId: SocketId): Promise<void> => {
	const { invalidateTokenUrl } = getServerOption();

	const params = new URLSearchParams({
		action: "token_delete",
		type: "ioat",
	});
	const url = `${invalidateTokenUrl}?${params.toString()}` as const;
	const init = {
		...REQUEST_INIT,
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({ id: socketId }),
	} as const satisfies RequestInit;
	const response = await fetch(url, init);

	if (response.ok) {
		// nothing todo
	} else {
		const data = await response.text();
		throw new RangeError("Invalid HTTP-Statuscode", { cause: { status: response.status, data: data } });
	}
};

let socketId: SocketId | undefined = undefined;

export const setSocketId = (newSocketId: SocketId | undefined) => {
	socketId = newSocketId;
};

export const getSocketId = (): SocketId | undefined => (socketId);

export const refreshAccessToken = async (): Promise<boolean> => {
	const refreshTokensResult = await tryRefreshAccessToken();
	if (refreshTokensResult === undefined) {
		return false; // refresh token expired
	}
	if (socketId) {
		const expires = await updateWebsocketLifetime(socketId);
		if (expires !== undefined) {
			restartAccessTokenAutoRefresh(expires);
			await invalidateOldAccessToken(refreshTokensResult.socketId);
		}
	} else {
		restartAccessTokenAutoRefresh(refreshTokensResult.expires);
		console.warn("no socketId set");
	}

	return true;
};

export const checkIsAuthenticated = async (): Promise<boolean> => {
	const expires = await checkIsAccessTokenValid();
	if (expires !== undefined) {
		restartAccessTokenAutoRefresh(expires);
		return true;
	}

	const refreshingAccessTokenSuccessfull = await refreshAccessToken();
	if (refreshingAccessTokenSuccessfull) {
		return true;
	}

	return false;
};
