import { useRef } from "react";
import { useNavigate, useParams } from "react-router";
import { useTranslation } from "react-i18next";
// cmp
import SearchAutocomplete from "../../cmp/SearchAutocomplete";
// hooks
import useSelectedGateway from "../../hooks/useSelectedGateway";
// contexts
import { useUserData } from "../../context/UserDataContext";
// services
import { Storage, StorageKeys } from "../../services/storage";
// types
import type { GatewayId } from "../../types/gateway";
import type { SearchOption } from "../../types/misc";

type RouteParams = {
	tab: string;
	gatewayId?: GatewayId;
};

const GatewaySearchCmp = () => {
	const { t } = useTranslation();
	const refSearchBox = useRef<any>();

	const navigate = useNavigate();
	const params = useParams<RouteParams>();

	const { ready } = useUserData();

	const { gateway } = useSelectedGateway();

	const handleItemSelected = (item: SearchOption) => {
		const itemId = encodeURIComponent(item.id);
		if (params.gatewayId !== itemId) {
			if (params.tab) {
				void navigate(`/gateways/${itemId}/${params.tab}`);
			} else {
				void navigate(`/gateways/${itemId}/general`);
			}
		}
	};

	const handleCleared = () => {
		Storage.remove(StorageKeys.gatewayId);
		Storage.remove(StorageKeys.gatewaysTab);

		void navigate("/gateways");
	};

	if (!ready) {
		return null;
	}

	return (
		<SearchAutocomplete
			ref={refSearchBox}
			placeholder={t("gateway.searchHint")}
			value={gateway ? `${gateway.name ?? gateway.id} (${gateway.id}) [${gateway.code ?? ""}]` : ""}
			kind="gateway"
			onItemSelected={handleItemSelected}
			onClear={handleCleared}
		/>
	);
};

export default GatewaySearchCmp;
