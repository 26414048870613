import { useState, useEffect } from "react";
import { useParams } from "react-router";
// contexts
import { useUserData } from "../context/UserDataContext";
// services
import Gupport from "../services/gupport";
import { Storage, StorageKeys } from "../services/storage";
// types
import type { User, UserId } from "../types/user";
import type { CmdGetB2CUser, CmdGetUser } from "../types/gupport";

type RouteParams = {
	userId?: UserId;
};

const useSelectedUser = () => {
	const params = useParams<RouteParams>();

	const { ready } = useUserData();

	const [loading, setLoading] = useState(false);
	const [isError, setIsError] = useState(false);
	const [selectedUser, setSelectedUser] = useState<User | null>(null);

	useEffect(() => {
		const storedUserId = Storage.get(StorageKeys.userId);
		if (params.userId || storedUserId) {
			if (ready) {
				const handleFetchUserResult = (error, msg) => {
					if (error || msg.payload.status === "error") {
						setIsError(true);
						setSelectedUser(null);
					} else {
						Storage.set(StorageKeys.userId, params.userId ?? storedUserId);
						setSelectedUser(msg.payload.data);
						setIsError(false);
					}
					setLoading(false);
				};
				setLoading(true);
				if (Storage.get(StorageKeys.isAdvancedSearchEnabled)) {
					const cmd = {
						action: "getB2CUser",
						sub: params.userId ?? storedUserId,
					} as const satisfies CmdGetB2CUser;
					Gupport.send(cmd, handleFetchUserResult);
				} else {
					const cmd = {
						action: "getUser",
						username: params.userId ?? storedUserId,
					} as const satisfies CmdGetUser;
					Gupport.send(cmd, handleFetchUserResult);
				}
			}
		} else {
			Storage.remove(StorageKeys.userId);
			Storage.remove(StorageKeys.usersTab);
			setSelectedUser(null);
		}
	}, [params.userId, ready]);

	return { loading, selectedUser, isError };
};

export default useSelectedUser;
