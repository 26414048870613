import MuiThemeProvider from "./MuiThemeProvider";
// contexts
import { ExpertModeProvider } from "./scripts/context/ExpertModeContext";
import { ServerOptionProvider } from "./scripts/context/ServerOptionContext";
// types
import type { ReactElement } from "react";

type Props = Readonly<{
	children: ReactElement;
	injectAdditionalContextProviders?: boolean;
}>;

const LocalProviders = ({ children, injectAdditionalContextProviders = false }: Props) => (
	<MuiThemeProvider>
		{injectAdditionalContextProviders ?
			<ExpertModeProvider>
				<ServerOptionProvider>
					{children}
				</ServerOptionProvider>
			</ExpertModeProvider>
			: children
		}
	</MuiThemeProvider>
);

export default LocalProviders;
