import { EventEmitter } from "events"; // eslint-disable-line n/prefer-node-protocol
// services
import { defaultServerOptionId } from "./serverOption";
import Constants from "./constants";
// @ts-expect-error: globalThis.appInfo
import appInfo from "@local/appInfo";
// types
import type { Channel, ServerId } from "../types/config";
import type { OAuthStateObj, UserId } from "../types/user";
import type { GatewayId } from "../types/gateway";
import type { Appearance } from "../types/misc";
import type { AppInfo } from "../types/global";
import type { ProviderId, ConfigCluster, OldAccount, StateObjs } from "../types/deprecated";

export const CURRENT_STORAGE_VERSION = 1;

export const StorageKeys = {
	storageVersion: "storageVersion",
	selectedBackendServer: "selectedBackendServer",
	// launchUrl: "launchUrl", // TODO
	expertMode: "expertMode",
	oAuthState: "oAuthState",
	gatewaysTab: "gateways/tab",
	usersTab: "users/tab",
	gatewayId: "gateways/gatewayId",
	userId: "users/userId",
	supportUserId: "support-users/userId",
	teditorTable: "teditor/table",
	rocIdFilterQuery: "rocIdFilterQuery",
	metadataEditorSearchAll: "metadataEditorSearchAll",
	metadataEditorSearchByRegex: "metadataEditorSearchByRegex",
	isAdvancedSearchEnabled: "isAdvancedSearchEnabled",
	appearance: "appearance",
	/** @deprecated */
	darkMode: "darkMode",
	/** @deprecated */
	menu: "menu/{MENU_ID}",
	/** @deprecated */
	settingsCluster: "settings/cluster",
	/** @deprecated */
	settingsClusterId: "settings/clusterId",
	/** @deprecated */
	settingsChannel: "settings/channel",
	/** @deprecated */
	accounts: "accounts",
	/** @deprecated */
	accountsDefaults: "accounts/defaults",
	/** @deprecated */
	providers: "providers/{PROVIDER_ID}/{KEY}",
} as const;

interface StorageValues {
	[StorageKeys.storageVersion]: number;
	[StorageKeys.selectedBackendServer]: ServerId;
	// [StorageKeys.launchUrl]: string; // TODO
	[StorageKeys.expertMode]: boolean;
	[StorageKeys.oAuthState]: OAuthStateObj;
	[StorageKeys.gatewaysTab]: string;
	[StorageKeys.usersTab]: string;
	[StorageKeys.gatewayId]: GatewayId;
	[StorageKeys.userId]: UserId;
	[StorageKeys.supportUserId]: UserId;
	[StorageKeys.teditorTable]: string;
	[StorageKeys.rocIdFilterQuery]: string;
	[StorageKeys.metadataEditorSearchAll]: boolean;
	[StorageKeys.metadataEditorSearchByRegex]: boolean;
	[StorageKeys.isAdvancedSearchEnabled]: boolean;
	[StorageKeys.appearance]: Appearance;
	/** @deprecated */
	[StorageKeys.darkMode]: boolean;
	/** @deprecated */
	[StorageKeys.menu]: boolean;
	/** @deprecated */
	[StorageKeys.settingsCluster]: ConfigCluster;
	/** @deprecated */
	[StorageKeys.settingsClusterId]: string;
	/** @deprecated */
	[StorageKeys.settingsChannel]: Channel;
	/** @deprecated */
	[StorageKeys.accounts]: Array<OldAccount>;
	/** @deprecated */
	[StorageKeys.accountsDefaults]: Array<OldAccount>;
	/** @deprecated */
	[StorageKeys.providers]: StateObjs;
}

const DEFAULT_VALUES = {
	[StorageKeys.storageVersion]: 0,
	[StorageKeys.selectedBackendServer]: defaultServerOptionId,
	// [StorageKeys.launchUrl]: null, // TODO
	[StorageKeys.expertMode]: false,
	[StorageKeys.oAuthState]: undefined,
	[StorageKeys.gatewaysTab]: null,
	[StorageKeys.usersTab]: null,
	[StorageKeys.gatewayId]: null,
	[StorageKeys.userId]: null,
	[StorageKeys.supportUserId]: null,
	[StorageKeys.teditorTable]: null,
	[StorageKeys.rocIdFilterQuery]: "",
	[StorageKeys.metadataEditorSearchAll]: true,
	[StorageKeys.metadataEditorSearchByRegex]: false,
	[StorageKeys.isAdvancedSearchEnabled]: false,
	[StorageKeys.appearance]: Constants.Appearance.System,
	/** @deprecated */
	[StorageKeys.darkMode]: false,
	/** @deprecated */
	[StorageKeys.menu]: false,
	/** @deprecated */
	[StorageKeys.settingsCluster]: undefined,
	/** @deprecated */
	[StorageKeys.settingsClusterId]: undefined,
	/** @deprecated */
	[StorageKeys.settingsChannel]: undefined,
	/** @deprecated */
	[StorageKeys.accounts]: [] as Array<OldAccount>,
	/** @deprecated */
	[StorageKeys.accountsDefaults]: [] as Array<OldAccount>,
	/** @deprecated */
	[StorageKeys.providers]: [] as StateObjs,
}; // TODO: satisfies StorageValues; // TODO: as const

// See "{...}" in StorageKeys
export interface ReplaceData {
	/** @deprecated */
	MENU_ID?: string;
	/** @deprecated */
	PROVIDER_ID?: ProviderId;
	/** @deprecated */
	KEY?: string;
}
type StorageKeyPrefix = "" | `${string}/${string}/`;
export type StorageKey = keyof StorageValues;
type StorageKeyWithPrefix<SK extends StorageKey = StorageKey> = `${StorageKeyPrefix}${SK}`;
type StorageValue<SK extends StorageKey = StorageKey> = StorageValues[SK] | null;
export type StorageValueWithDefault<SK extends StorageKey = StorageKey> = StorageValues[SK] | (typeof DEFAULT_VALUES)[SK];
type StorageType = "localStorage" | "sessionStorage" | "memory";

interface StorageT<ST extends StorageType = StorageType> {
	type: ST;
	isAvailable: () => boolean;
	needsPrefix: () => boolean;
	updateItems: (storageKeyPrefix: StorageKeyPrefix) => void;
	getItem: (key: StorageKeyWithPrefix) => StorageValue;
	setItem: (key: StorageKeyWithPrefix, value: StorageValue) => void;
	removeItem: (key: StorageKeyWithPrefix) => void;
	getKeys: () => Set<StorageKeyWithPrefix>;
	clear: () => void;
}

/**
 * A service class responsible to store the data in browser.
 * The storage used is localstorage then sessionStorage then memory.
 */

const getLocalOrSessionStorage = <ST extends "localStorage" | "sessionStorage">(storageType: ST) => ({
	type: storageType,
	isAvailable: () => {
		try {
			const storageTestString = "__storage_test__";
			globalThis[storageType].setItem(storageTestString, storageTestString);
			globalThis[storageType].removeItem(storageTestString);
			return true;
		} catch (_error) {
			return false;
		}
	},
	needsPrefix: () => true,
	updateItems: (storageKeyPrefix) => {
		for (let i = 0; i < globalThis[storageType].length; i++) {
			const key = globalThis[storageType].key(i);
			if (key?.startsWith(storageKeyPrefix)) {
				const value = globalThis[storageType].getItem(key);
				try {
					JSON.parse(value);
				} catch (_error) {
					globalThis[storageType].setItem(key, JSON.stringify(value));
				}
			}
		}
	},
	getItem: (key) => (JSON.parse(globalThis[storageType].getItem(key)) as StorageValue),
	setItem: (key, value) => (globalThis[storageType].setItem(key, JSON.stringify(value))),
	removeItem: (key) => (globalThis[storageType].removeItem(key)),
	getKeys: () => {
		const keys = new Set<StorageKeyWithPrefix>();
		for (let i = 0; i < globalThis[storageType].length; i++) {
			keys.add(globalThis[storageType].key(i) as StorageKeyWithPrefix);
		}
		return keys;
	},
	clear: () => (globalThis[storageType].clear()),
} satisfies StorageT<ST>);

const memoryStorage = new Map<StorageKeyWithPrefix, StorageValue>();

const localStorage = getLocalOrSessionStorage("localStorage");
const sessionStorage = getLocalOrSessionStorage("sessionStorage");
const memory = {
	type: "memory",
	isAvailable: () => true,
	needsPrefix: () => false,
	updateItems: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
	getItem: (key) => (memoryStorage.get(key) ?? null),
	setItem: (key, value) => (memoryStorage.set(key, value)),
	removeItem: (key) => (memoryStorage.delete(key)),
	getKeys: () => (new Set(memoryStorage.keys())),
	clear: () => (memoryStorage.clear()),
} satisfies StorageT<"memory">;

export const DEFAULT_REPLACE_DATA = {} as const satisfies ReplaceData;

class StorageApi extends EventEmitter {

	#storage: StorageT;
	#storageKeyPrefix: StorageKeyPrefix = "";

	constructor() {
		super();

		this.setMaxListeners(256); // a listener per device in the devices view

		const storages = [localStorage, sessionStorage] as const;
		this.#storage = storages.find((storage) => (storage.isAvailable())) ?? memory;

		if (this.#storage.needsPrefix()) {
			const lastSlashIndex = globalThis.location.pathname.lastIndexOf("/");
			const pathname = (lastSlashIndex === -1) ? globalThis.location.pathname : globalThis.location.pathname.substring(0, lastSlashIndex);
			this.#storageKeyPrefix = `${pathname}/${(appInfo as AppInfo).name}/` as StorageKeyPrefix;
		}

		this.#storage.updateItems(this.#storageKeyPrefix);

		if (this.#storage.type === "localStorage") {
			// "storage" event fires if local-storage got modified by other window
			window.addEventListener("storage", ({ key, newValue }) => {
				if (key?.startsWith(this.#storageKeyPrefix)) {
					const storageKey = Object.values(StorageKeys).find((storageKey) => (
						(new RegExp(`^${this.#storageKeyPrefix}${storageKey.replaceAll(/\{\w+\}/g, "[^/]+")}$`)).test(key)
					));
					if (storageKey) {
						const replaceDataValues = new RegExp(`^${this.#storageKeyPrefix}${storageKey.replaceAll(/\{(?<key>\w+)\}/g, "(?<$<key>>[^/]+)")}$`).exec(key);
						const replaceData = replaceDataValues?.groups as ReplaceData | undefined ?? {};
						for (const key of Object.keys(replaceData)) {
							const value = replaceData[key];
							if (value) {
								replaceData[key] = decodeURIComponent(value);
							}
						}
						if (newValue === null) {
							this.emit(`${storageKey}Removed`, replaceData);
						} else {
							this.emit(`${storageKey}Changed`, JSON.parse(newValue) as StorageValueWithDefault, replaceData);
						}
					}
				}
			});
		}
	}

	#getStorageKey<SK extends StorageKey>(storageKey: SK, replaceData: ReplaceData): StorageKeyWithPrefix<SK> {
		let key: string = storageKey;

		for (const replaceKey of Object.keys(replaceData)) {
			key = key.replaceAll(new RegExp(`{${replaceKey}}`, "g"), encodeURIComponent(replaceData[replaceKey]));
		}

		return `${this.#storageKeyPrefix}${key}` as StorageKeyWithPrefix<SK>;
	}

	public type(): StorageType {
		return this.#storage.type;
	}

	public clear(): void {
		this.#storage.clear();
	}

	public has<SK extends StorageKey>(storageKey: SK, replaceData: ReplaceData = DEFAULT_REPLACE_DATA): boolean {
		return this.#storage.getItem(this.#getStorageKey(storageKey, replaceData)) as StorageValue<SK> !== null;
	}

	public get<SK extends StorageKey>(storageKey: SK, replaceData: ReplaceData = DEFAULT_REPLACE_DATA, fallbackReplaceData?: ReplaceData): StorageValueWithDefault<SK> {
		if (fallbackReplaceData === undefined || this.has(storageKey, replaceData)) {
			return this.#storage.getItem(this.#getStorageKey(storageKey, replaceData)) as StorageValue<SK> ?? DEFAULT_VALUES[storageKey];
		}
		const value = this.#storage.getItem(this.#getStorageKey(storageKey, fallbackReplaceData)) as StorageValue<SK> ?? DEFAULT_VALUES[storageKey];
		if (this.has(storageKey, fallbackReplaceData)) {
			this.set(storageKey, value, replaceData);
			this.remove(storageKey, fallbackReplaceData);
		}
		return value;
	}

	public set<SK extends StorageKey>(storageKey: SK, storageValue: StorageValueWithDefault<SK>, replaceData: ReplaceData = DEFAULT_REPLACE_DATA): void {
		this.#storage.setItem(this.#getStorageKey(storageKey, replaceData), storageValue);
		this.emit(`${storageKey}Changed`, storageValue, replaceData);
	}

	public remove<SK extends StorageKey>(storageKey: SK, replaceData: ReplaceData = DEFAULT_REPLACE_DATA): void {
		this.#storage.removeItem(this.#getStorageKey(storageKey, replaceData));
		this.emit(`${storageKey}Removed`, replaceData);
	}

	public getKeys(): Set<StorageKey> {
		if (this.#storage.needsPrefix()) {
			const keys = Array.from(this.#storage.getKeys())
				.filter((storageKeyWithPrefix) => (storageKeyWithPrefix.startsWith(this.#storageKeyPrefix)))
				.map((storageKeyWithPrefix) => (storageKeyWithPrefix.substring(this.#storageKeyPrefix.length))) as ReadonlyArray<StorageKey>;

			return new Set(keys);
		} else {
			return this.#storage.getKeys() as Set<StorageKey>;
		}
	}

	public getAll(): Map<StorageKey, StorageValueWithDefault> {
		const data = new Map<StorageKey, StorageValueWithDefault>();
		for (const storageKey of this.getKeys()) {
			data.set(storageKey, this.get(storageKey));
		}
		return data;
	}

	public logoutCleanup(): void {
		// update unit test if `KEEP_STORAGE_KEYS` array gets changed
		const KEEP_STORAGE_KEYS = [
			StorageKeys.storageVersion,
			StorageKeys.selectedBackendServer,
			// StorageKeys.launchUrl, // TODO
			StorageKeys.expertMode,
			StorageKeys.oAuthState,
			// StorageKeys.appVersion,
		] as const satisfies ReadonlyArray<StorageKey>;

		const regexKeepStorageKeys = KEEP_STORAGE_KEYS.map((keepStorageKey) => (this.#getStorageKeyRegex(keepStorageKey)));

		for (const storageKey of this.getKeys()) {
			if (!regexKeepStorageKeys.some((regexKeepStorageKey) => (regexKeepStorageKey.test(storageKey)))) {
				this.remove(storageKey);
			}
		}
	}

	public migrate(): void {
		/* eslint-disable no-fallthrough */
		switch (this.get(StorageKeys.storageVersion)) {
			case 0:
				this.#migrateToVersion1();
				// ! add here new migration steps
				// case 1:
				// 	this.#migrateToVersion2();
				// ! don't forget to update CURRENT_STORAGE_VERSION
				console.info("Storage migration done");
			case CURRENT_STORAGE_VERSION:
				console.info("Storage up to date");
				break;
			default:
				console.warn("Storage migration not implemented for version", this.get(StorageKeys.storageVersion));
		}
		/* eslint-enable no-fallthrough */

		this.set(StorageKeys.storageVersion, CURRENT_STORAGE_VERSION);
	}

	#getStorageKeyRegex(storageKey: StorageKey): RegExp {
		return new RegExp(`^${storageKey.replaceAll(/{[^}]+}/g, ".+")}$`);
	}

	#migrateToVersion1(): void {
		this.#setSelectedBackendServer();
		this.#removeOldUnusedData();
	}

	#setSelectedBackendServer(): void {
		if (!this.has(StorageKeys.selectedBackendServer)) {
			this.set(StorageKeys.selectedBackendServer, defaultServerOptionId);
		}
	}

	#removeOldUnusedData(): void {
		this.remove(StorageKeys.darkMode);
		this.remove(StorageKeys.settingsCluster);
		this.remove(StorageKeys.settingsClusterId);
		this.remove(StorageKeys.settingsChannel);
		this.remove(StorageKeys.accounts);
		this.remove(StorageKeys.accountsDefaults);
		this.remove(StorageKeys.providers, { PROVIDER_ID: "oauth2-cookie-1", KEY: "loginStates" });
		this.remove(StorageKeys.providers, { PROVIDER_ID: "oauth2-cookie-1", KEY: "logoutStates" });
		// removed after capacitor migration
		this.remove(StorageKeys.menu, { MENU_ID: "submenu-settings" });
	}

}

export const Storage = new StorageApi();
