import { useState, useEffect } from "react";

type Primitive = undefined | null | boolean | number | string;

const useDynamicUpdateState = <V extends Primitive>(newValue: V) => {
	const [value, setValue] = useState(newValue);

	useEffect(() => {
		setValue(newValue);
	}, [newValue]);

	return [value, setValue] as const;
};

export default useDynamicUpdateState;
