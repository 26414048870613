import { memo } from "react";
import { matchPath, Link, useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import { Button, IconButton, Skeleton, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
// cmp
import Svg from "../cmp/svg";
// services
import { icons } from "@local/theme";
// types
import type { ReactNode } from "react";

const SUB_NAV_PAGE_ROUTES = [
	"/gateways/:gatewayId/:tab/zigbeeNetworkMap",
	"/teditor/:tableId/:rocId",
] as const;

type Props = Readonly<{
	children?: ReactNode;
	title?: string | null;
	backClickPath?: string;
	externalPage?: boolean;
	navigationDrawerOpen?: boolean;
	onNavigationDrawerToggle: () => void;
}>;

const ToolbarBackBtnAndTitle = ({ title, backClickPath, externalPage, navigationDrawerOpen, onNavigationDrawerToggle, children }: Props) => {
	const { t } = useTranslation();

	const theme = useTheme();

	const { pathname } = useLocation();

	const isSubPageRoutes = SUB_NAV_PAGE_ROUTES.some((pattern) => (matchPath(pattern, pathname)));

	if (pathname !== "/") {
		if (title === undefined) {
			console.error("title is undefined", pathname);
		} else if (title === "") {
			console.warn("title is empty", pathname);
		}
	}

	return (
		<>
			{isSubPageRoutes
				? (
					<Button
						id="btn-toolbar-back"
						component={Link}
						to={backClickPath ?? "./.."}
						color="primary"
						startIcon={<icons.ChevronLeft />}
					>
						{t("generic.appBarBackText")}
					</Button>
				)
				: (!externalPage &&
					<IconButton
						id="btn-navigation-drawer-toggle"
						edge={navigationDrawerOpen ? false : "start"}
						onClick={onNavigationDrawerToggle}
					>
						<Svg src="navigation/navigationMenuIcon.svg" color={theme.palette.primary.main} />
					</IconButton>
				)
			}
			{title !== null && <Typography variant="h6" noWrap={true} sx={{ marginLeft: { xs: "12px", md: undefined } }}>{title ?? <Skeleton width={256} />}</Typography>}
			<div style={{ flexGrow: 1, marginLeft: "12px" }}>{children}</div>
		</>
	);
};

export default memo(ToolbarBackBtnAndTitle);
