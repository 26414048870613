import { useEffect, useState } from "react";
import { useLocation, Outlet } from "react-router";
import { useMediaQuery } from "@mui/material";
// cmp
import NavigationDrawer from "./scripts/cmp/navigation/navigation-drawer";
import AppBarCmp from "./scripts/cmp/AppBarCmp";
// hooks
import useDynamicUpdateState from "./scripts/hooks/useDynamicUpdateState";
// services
import Responsive from "./scripts/services/responsive";

type Props = Readonly<{
	hideAppBar?: boolean;
	externalPage?: boolean;
}>;

const NAVIGATION_DRAWER_WIDTH = "300px";

const PageContainer = ({ hideAppBar = false, externalPage = false }: Props) => {
	const location = useLocation();

	const docked = useMediaQuery((theme) => (theme.breakpoints.up("lg")));

	const [navigationDrawerDocked, setNavigationDrawerDocked] = useDynamicUpdateState(docked);
	const [navigationDrawerOpen, setNavigationDrawerOpen] = useState(docked);

	useEffect(() => {
		const handleScreenChanged = () => {
			setNavigationDrawerDocked(Responsive.getScreenSizes().includes("lg"));
		};

		Responsive.on("screenChanged", handleScreenChanged);

		return () => {
			Responsive.off("screenChanged", handleScreenChanged);
		};
	}, []);

	const handleNavigationDrawerToggle = () => {
		setNavigationDrawerOpen(!navigationDrawerOpen);
		setNavigationDrawerDocked(!navigationDrawerOpen);
	};

	const _navigationDrawerOpen = (docked && navigationDrawerDocked) || navigationDrawerOpen;

	const contentLeftMargin = (docked && navigationDrawerDocked && !externalPage) ? NAVIGATION_DRAWER_WIDTH : "0px";

	return (
		<>
			{!hideAppBar &&
				<AppBarCmp
					externalPage={externalPage}
					navigationDrawerOpen={_navigationDrawerOpen}
					contentLeftMargin={contentLeftMargin}
					onNavigationDrawerToggle={handleNavigationDrawerToggle}
				/>
			}
			{!externalPage &&
				<NavigationDrawer
					open={_navigationDrawerOpen}
					activeRoute={location.pathname}
					onOpenChanged={(open) => (setNavigationDrawerOpen(open))}
					width={NAVIGATION_DRAWER_WIDTH}
					docked={navigationDrawerDocked}
				/>
			}
			<main id="content" style={{ marginLeft: contentLeftMargin, height: "calc(100% - 64px)", overflowY: "auto", marginTop: "64px" }}>
				<div id="content-container">
					<Outlet />
				</div>
			</main>
		</>
	);
};

export default PageContainer;
