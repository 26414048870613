import { Routes, Route } from "react-router";
import { AppBar, Toolbar } from "@mui/material";
// cmp
import ToolbarBackBtnAndTitleRoutes from "./ToolbarBackBtnAndTitleRoutes";
import GatewayRightIcon from "./gateway/GatewayRightIcon";
import UserRightIcon from "./user/UserRightIcon";
import SupportUserRightIcon from "./support-user/SupportUserRightIcon";

type Props = Readonly<{
	externalPage: boolean;
	navigationDrawerOpen: boolean;
	contentLeftMargin: string;
	onNavigationDrawerToggle: () => void;
}>;

const AppBarCmp = ({ externalPage, navigationDrawerOpen, contentLeftMargin, onNavigationDrawerToggle }: Props) => (
	<AppBar
		elevation={1}
		color="inherit"
		sx={{ width: `calc(100% - ${contentLeftMargin})` }}
	>
		<Toolbar>
			<ToolbarBackBtnAndTitleRoutes
				externalPage={externalPage}
				navigationDrawerOpen={navigationDrawerOpen}
				onNavigationDrawerToggle={onNavigationDrawerToggle}
			/>
			<Routes>
				{!externalPage &&
					<>
						<Route path="gateways">
							<Route index={true} element={<GatewayRightIcon />} />
							<Route path=":gatewayId" element={<GatewayRightIcon />} />
							<Route path=":gatewayId/:tab" element={<GatewayRightIcon />} />
						</Route>
						<Route path="users">
							<Route index={true} element={<UserRightIcon />} />
							<Route path=":userId" element={<UserRightIcon />} />
							<Route path=":userId/:tab" element={<UserRightIcon />} />
						</Route>
						<Route path="support/users">
							<Route index={true} element={<SupportUserRightIcon />} />
							<Route path=":userId" element={<SupportUserRightIcon />} />
						</Route>
					</>
				}
				{/* <Route path="/carinfo" element={<AddMenu />} />
					<Route path="/devices/:tabId" element={<AddMenu />} />
					<Route path="/rules/:tabId" element={<AddMenu />} />
					<Route path="/alerts" element={<AddMenu />} />
					<Route path="/history/:tabId" element={<AddMenu />} /> */}
			</Routes>
		</Toolbar>
	</AppBar>
);

export default AppBarCmp;
