import { memo } from "react";
import { Routes, Route } from "react-router";
import { useTranslation } from "react-i18next";
// cmps
import ToolbarBackBtnAndTitle from "./ToolbarBackBtnAndTitle";
import GatewaySearchCmp from "./gateway/GatewaySearchCmp";
import UserSearchCmp from "./user/UserSearchCmp";
import SupportUserSearchCmp from "./support-user/SupportUserSearchCmp";
// contexts
import { useUserData } from "../context/UserDataContext";

type Props = Readonly<{
	externalPage: boolean;
	navigationDrawerOpen: boolean;
	onNavigationDrawerToggle: () => void;
}>;

const ToolbarBackBtnAndTitleRoutes = (props: Props) => {
	const { t } = useTranslation();

	const { user } = useUserData();

	return (
		<Routes>
			<Route path="welcome" element={<ToolbarBackBtnAndTitle {...props} title={`Welcome ${(user?.display_name ?? "Mr. Anonymous")}`} />} />
			<Route path="gateways">
				<Route index={true} element={<ToolbarBackBtnAndTitle {...props} title={null}><GatewaySearchCmp /></ToolbarBackBtnAndTitle>} />
				<Route path=":gatewayId" element={<ToolbarBackBtnAndTitle {...props} title={null}><GatewaySearchCmp /></ToolbarBackBtnAndTitle>} />
				<Route path=":gatewayId/:tab">
					<Route index={true} element={<ToolbarBackBtnAndTitle {...props} title={null}><GatewaySearchCmp /></ToolbarBackBtnAndTitle>} />
					<Route path="zigbeeNetworkMap" element={<ToolbarBackBtnAndTitle {...props} title={t("gateway.networkMap.title")} backClickPath="./.." />} />
				</Route>
			</Route>
			<Route path="users">
				<Route index={true} element={<ToolbarBackBtnAndTitle {...props} title={null}><UserSearchCmp /></ToolbarBackBtnAndTitle>} />
				<Route path=":userId">
					<Route index={true} element={<ToolbarBackBtnAndTitle {...props} title={null}><UserSearchCmp /></ToolbarBackBtnAndTitle>} />
					<Route path=":tab" element={<ToolbarBackBtnAndTitle {...props} title={null}><UserSearchCmp /></ToolbarBackBtnAndTitle>} />
				</Route>
			</Route>
			<Route path="stats/gerver" element={<ToolbarBackBtnAndTitle {...props} title={t("statistics.title")} />} />
			<Route path="glogs" element={<ToolbarBackBtnAndTitle {...props} title={t("glogs.title")} />} />
			<Route path="playground">
				<Route path="glient" element={<ToolbarBackBtnAndTitle {...props} title={t("playground.titleGlient")} />} />
				<Route path="gupport" element={<ToolbarBackBtnAndTitle {...props} title={t("playground.titleGupport")} />} />
			</Route>
			<Route path="support/users">
				<Route index={true} element={<ToolbarBackBtnAndTitle {...props} title={null}><SupportUserSearchCmp /></ToolbarBackBtnAndTitle>} />
				<Route path=":userId" element={<ToolbarBackBtnAndTitle {...props} title={null}><SupportUserSearchCmp /></ToolbarBackBtnAndTitle>} />
			</Route>
			<Route path="teditor">
				<Route index={true} element={<ToolbarBackBtnAndTitle {...props} title={t("teditor.title")} />} />
				<Route path=":tableId">
					<Route index={true} element={<ToolbarBackBtnAndTitle {...props} title={t("teditor.title")} />} />
					<Route path=":stringKey" element={<ToolbarBackBtnAndTitle {...props} title={t("teditor.title")} backClickPath="./.." />} />
				</Route>
			</Route>
			<Route path="backups" element={<ToolbarBackBtnAndTitle {...props} title={t("backup.title")} />} />
			<Route path="utilities/localization" element={<ToolbarBackBtnAndTitle {...props} title={t("localizationUtitlity.title")} />} />
			<Route path="about" element={<ToolbarBackBtnAndTitle {...props} title={t("menu.about")} />} />
			<Route path="general-settings" element={<ToolbarBackBtnAndTitle {...props} title={t("menu.generalSettings")} />} />
			<Route path="logs" element={<ToolbarBackBtnAndTitle {...props} title={t("logs.title")} />} />
			<Route path="settings" element={<ToolbarBackBtnAndTitle {...props} title={t("settings.title")} />} />

			<Route path="*" element={<ToolbarBackBtnAndTitle {...props} title={undefined} />} />
		</Routes>
	);
};

export default memo(ToolbarBackBtnAndTitleRoutes);
