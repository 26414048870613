import { useParams } from "react-router";
import { IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
// cmp
import Svg from "../svg";
// types
import type { GatewayId } from "../../types/gateway";

type RouteParams = {
	gatewayId: GatewayId;
};

const GatewayRightIcon = () => {
	const theme = useTheme();

	const params = useParams<RouteParams>();

	const handleOpenNewWindow = () => {
		if (params.gatewayId) {
			window.open(
				`gateway_desc.html#/gateways/${encodeURIComponent(params.gatewayId)}/general`,
				"_blank"
			);
		}
	};

	return (
		<IconButton disabled={!params.gatewayId} onClick={handleOpenNewWindow}>
			<Svg src="navigation/openNewWindow.svg" color={params.gatewayId ? theme.palette.primary.main : theme.palette.text.disabled} />
		</IconButton>
	);
};

export default GatewayRightIcon;
