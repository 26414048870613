import { Navigate } from "react-router";
// services
import User from "./scripts/services/user";

type Props = Readonly<{
	to: string;
}>;

const RootRenderElement = ({ to }: Props) => {
	const url = new URL(globalThis.location.href);
	void User.manageSearchParams(); // TODO
	url.search = "";
	globalThis.history.replaceState(null, "", url.href); // TODO: remove on switch to BrowserRouter
	return <Navigate to={to} />;
};

export default RootRenderElement;
