import { useRef } from "react";
import { useNavigate, useParams } from "react-router";
import { useTranslation } from "react-i18next";
// cmp
import SearchAutocomplete from "../SearchAutocomplete";
// hooks
import useSelectedUser from "../../hooks/useSelectedUser";
// contexts
import { useUserData } from "../../context/UserDataContext";
// services
import { Storage, StorageKeys } from "../../services/storage";
// types
import type { UserId } from "../../types/user";
import type { SearchOption } from "../../types/misc";

type RouteParams = {
	tab: string;
	userId?: UserId;
};

const UserSearchCmp = () => {
	const { t } = useTranslation();
	const refSearchBox = useRef<any>();

	const navigate = useNavigate();
	const params = useParams<RouteParams>();

	const { ready } = useUserData();
	const { selectedUser } = useSelectedUser();

	const handleItemSelected = (item: SearchOption) => {
		const userId = Storage.get(StorageKeys.isAdvancedSearchEnabled) ? item.id : item.secondary;
		if (params.userId !== userId) {
			if (params.tab) {
				void navigate(`/users/${userId}/${params.tab}`);
			} else {
				void navigate(`/users/${userId}/general`);
			}
		}
	};

	const handleCleared = () => {
		Storage.remove(StorageKeys.userId);
		Storage.remove(StorageKeys.usersTab);

		void navigate("/users");
	};

	if (!ready) {
		return null;
	}

	return (
		<SearchAutocomplete
			ref={refSearchBox}
			placeholder={t("users.searchHint")}
			value={selectedUser && params.userId ? `${selectedUser.name ?? params.userId} (${params.userId as string})` : ""}
			kind="user"
			onItemSelected={handleItemSelected}
			onClear={handleCleared}
		/>
	);
};

export default UserSearchCmp;
