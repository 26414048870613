import deepmerge from "deepmerge";
// types
import type { MergeDeep } from "type-fest";

export const merge = (...params: Array<object>): object => (Object.assign({}, ...params));

export const mergeDeep = <O1, O2>(obj1: O1, obj2: O2): MergeDeep<O1, O2> => (
	deepmerge<O1, O2>(obj1, obj2, { arrayMerge: (_destinationArray, sourceArray) => (sourceArray) }) as MergeDeep<O1, O2>
);

export const formatBytes = (bytes: number, decimals: number = 2): string => {
	if (!bytes) {
		return "0 Bytes";
	}
	const k = 1000;
	const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"] as const;
	const i = Math.floor(Math.log(bytes) / Math.log(k));
	return `${Number.parseFloat((bytes / Math.pow(k, i)).toFixed(decimals))} ${sizes[i] as (typeof sizes)[number]}`;
};

export const compareObjects = (o1: object, o2: object): boolean => {
	for (const p in o1) {
		if (o1.hasOwnProperty(p)) {
			if (o1[p] !== o2[p]) {
				return false;
			}
		}
	}
	for (const p in o2) {
		if (o2.hasOwnProperty(p)) {
			if (o1[p] !== o2[p]) {
				return false;
			}
		}
	}
	return true;
};

export const roundToPrecision = (number: number, precision: number = 0.1): number => (
	Math.round(number / precision) / (1 / precision) // Is a hack for correct rounding of: Math.round(number / precision) * precision
);

export const getNumberRange = (min: number, max: number, step: number = 1): Array<number> => {
	const numberRange: Array<number> = [];
	// We have to round here because of how JavaScript handles decimal values. (0.2 + 0.1 = 0.30000000000000004)
	for (let i = min; i <= max; i = roundToPrecision(i + step, step)) {
		numberRange.push(i);
	}
	return numberRange;
};

export const decimal2Hex = (decimal: number, length: number | undefined = undefined, signed: boolean = false): string => {
	const value = (typeof length === "number" && (signed || decimal < 0)) ? decimal & (Math.pow(16, length) - 1) : decimal;
	const hex = value.toString(16).toUpperCase();
	if (length === undefined) {
		return hex;
	}
	return hex.padStart(length, "0");
};

export const isRocBuildUrl = () => (globalThis.location.origin === "https://rocbuild.blob.core.windows.net");

export const getRefererUrl = () => (`${globalThis.location.origin}${globalThis.location.pathname}${globalThis.location.search}`);

export const BBCODE_BOLD_REGEX = /\[b](.+?)\[\/b]/;
