import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
	Collapse,
	List,
	ListItemButton,
	ListItemAvatar,
	ListItemText,
	Divider,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Svg from "../svg";
// services
import { Storage, StorageKeys } from "../../services/storage";
import { icons } from "@local/theme";
// types
import type { ReactNode } from "react";
import type { MenuItems, MenuItemSubMenu } from "../../types/menu";

type Props = Readonly<{
	activeRoute: string;
	item: MenuItemSubMenu;
	renderNestedMenu: (menuItems: MenuItems, nested: boolean, activeRoute: string) => ReactNode;
}>;

const NavigationNestedMenu = ({ activeRoute, item, renderNestedMenu }: Props) => {
	const theme = useTheme();
	const { t } = useTranslation();

	const active = item.items.some((item) => (item.id === activeRoute));
	const [open, setOpen] = useState(active || (Storage.get(StorageKeys.menu.replace("{MENU_ID}", item.id) as "menu/{MENU_ID}") ?? false));

	useEffect(() => {
		if (active) {
			setOpen(active);
		}
	}, [active]);

	useEffect(() => {
		Storage.set(StorageKeys.menu.replace("{MENU_ID}", item.id) as "menu/{MENU_ID}", open);
	}, [open]);

	const IconElement = item.icon;

	return (
		<>
			{!item.singleDivider && <Divider />}
			<ListItemButton
				id={`main-menu-${item.id}`}
				style={{ color: open ? theme.palette.text.primary : theme.palette.text.secondary }}
				onClick={() => (setOpen((prevOpen) => (!prevOpen)))}
			>
				{IconElement && <ListItemAvatar><IconElement /></ListItemAvatar>}
				<ListItemText>{t(item.label)}</ListItemText>
				<ListItemAvatar className="ListItemIcon-right" style={{ display: "flex" }}>
					{open ? <icons.ExpandLess /> : <Svg src="navigation/arrowDropDown.svg" />}
				</ListItemAvatar>
			</ListItemButton>
			<Collapse in={open} timeout="auto" unmountOnExit={true}>
				<List component="div" disablePadding={true}>
					{renderNestedMenu(item.items, true, activeRoute)}
				</List>
			</Collapse>
			<Divider />
		</>
	);
};

export default NavigationNestedMenu;
