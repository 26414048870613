import { useState, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
// contexts
import { useUserData } from "../../context/UserDataContext";
// services
import User from "../../services/user";
import Gupport from "../../services/gupport";
// types
import type { MsgDataWithPayload } from "../../types/roc-ws";

type Props = Readonly<{
	doNotRedirect?: boolean;
}>;

const ConnectionFailedDialog = ({ doNotRedirect = false }: Props) => {
	const { t } = useTranslation();

	const { userId } = useUserData();

	const [open, setOpen] = useState(false);
	const [gupportPayload, setGupportPayload] = useState<MsgDataWithPayload["payload"] | null>(null);

	useEffect(() => {
		const handleGupportLoginFailed = (msg: MsgDataWithPayload) => {
			if (doNotRedirect) {
				setOpen(true);
				setGupportPayload(msg.payload);
			} else {
				User.login();
			}
		};

		Gupport.on("loginFailed", handleGupportLoginFailed);

		return () => {
			Gupport.off("loginFailed", handleGupportLoginFailed);
		};
	}, [doNotRedirect]);

	return (
		<Dialog
			fullWidth={true}
			open={open}
			onClose={() => (setOpen(false))}
		>
			<DialogTitle>{t("dialog.title.connectionFailed")}</DialogTitle>
			<DialogContent>
				<DialogContentText>
					<Trans
						i18nKey="dialog.content.connectionFailed" // optional -> fallbacks to defaults if not provided
						values={{ accountId: userId ?? "none", response: JSON.stringify(gupportPayload, null, 2) }}
						components={{ p: <p />, pre: <pre /> }}
					/>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => (setOpen(false))}>{t("dialog.ok")}</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ConnectionFailedDialog;
