import { useState, useEffect } from "react";
import { useParams } from "react-router";
// contexts
import { useUserData } from "../context/UserDataContext";
// services
import Gupport from "../services/gupport";
import { Storage, StorageKeys } from "../services/storage";
// types
import type { Gateway, GatewayId } from "../types/gateway";
import type { CmdGetGateway } from "../types/gupport";

type RouteParams = {
	gatewayId?: GatewayId;
};

const useSelectedGateway = () => {
	const params = useParams<RouteParams>();

	const { ready } = useUserData();

	const [loading, setLoading] = useState(false);
	const [gateway, setGateway] = useState<Gateway | null>(null);

	useEffect(() => {
		const storedGatewayId = Storage.get(StorageKeys.gatewayId);
		if (params.gatewayId || storedGatewayId) {
			if (ready) {
				setLoading(true);
				const cmd = {
					action: "getGateway",
					gatewayId: params.gatewayId ?? storedGatewayId,
				} as const satisfies CmdGetGateway;
				Gupport.send(cmd, (error, msg) => {
					if (!error && msg?.payload.status === "ok") {
						Storage.set(StorageKeys.gatewayId, params.gatewayId ?? storedGatewayId);
						setGateway(msg.payload.data);
					} else {
						setGateway(null);
					}
					setLoading(false);
				});
			}
		} else {
			Storage.remove(StorageKeys.gatewayId);
			Storage.remove(StorageKeys.gatewaysTab);
			setGateway(null);
		}
	}, [params.gatewayId, ready]);

	return { gateway, loading };
};

export default useSelectedGateway;
