import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, IconButton, TextField, ListItemText, Autocomplete, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import { useTheme } from "@mui/material/styles";
// services
import Gupport from "../../services/gupport";
import { sortAlphabetically } from "../../services/l10n";
import { icons } from "@local/theme";
// types
import type { SyntheticEvent } from "react";
import type { CmdAddGlogin, CmdGetNewGlogins } from "../../types/gupport";
import type { SupportUser } from "../../types/user";

type SupportUserList = Array<SupportUser>;

const DEFAULT_LEVELS = ["gw_read"] as const;

const sortFunc = (supportUserA: SupportUser, supportUserB: SupportUser): number => (sortAlphabetically(supportUserA.sortKey, supportUserB.sortKey));

const SupportUserInvite = () => {
	const { t } = useTranslation();
	const theme = useTheme();

	const timeoutIdRef = useRef<number | undefined>(undefined);

	const [supportUsers, setSupportUsers] = useState<SupportUserList>([]);
	const [selectedItem, setSelectedItem] = useState<SupportUser | null>(null);
	const [invitingUser, setInvitingUser] = useState(false);
	const [optionsLoading, setOptionsLoading] = useState(false);
	const [openErrorDialog, setOpenErrorDialog] = useState(false);
	const [openInviteDialog, setOpenInviteDialog] = useState(false);

	useEffect(() => (
		() => {
			window.clearTimeout(timeoutIdRef.current);
		}
	), []);

	const fetchNewGlogins = (_searchFilter: string) => {
		if (Gupport.getNewGlogins) {
			setOptionsLoading(true);

			const cmd = {
				action: "getNewGlogins",
				data: _searchFilter,
			} as const satisfies CmdGetNewGlogins;
			Gupport.send(cmd, (error, msg) => {
				if (!error && msg?.payload.status === "ok") {
					setOptionsLoading(false);
					setSupportUsers(msg.payload.data.map((item) => ({
						id: item.supUserId,
						user: item,
						label: `${item.displayName} (${item.userName}) [${item.supUserId}]`,
						search: [item.supUserId, item.userName, item.displayName],
						primary: `${item.displayName} (${item.userName})`,
						secondary: item.supUserId,
						sortKey: `${item.displayName}#${item.userName}#${item.supUserId}`,
					})).toSorted(sortFunc));
				} else {
					setOpenInviteDialog(false);
					setSupportUsers([]);
				}
			});
		}
	};

	const handleInputChange = (event: SyntheticEvent, value: string) => {
		if (value.length >= 3) {
			window.clearTimeout(timeoutIdRef.current);
			timeoutIdRef.current = window.setTimeout(() => {
				fetchNewGlogins(value);
			}, 500);
		}
	};

	const showInviteDialog = () => {
		setOpenInviteDialog(true);
		setSupportUsers([]);
		setSelectedItem(null);
	};

	const handleDialogClose = () => {
		setOpenInviteDialog(false);
		console.info(selectedItem);
		setSelectedItem(null);
		setOpenErrorDialog(false);
	};

	const handleInviteUserClick = () => {
		setInvitingUser(true);
		const cmd = {
			action: "addGlogin",
			...selectedItem?.user, // TODO
			level: DEFAULT_LEVELS,
		} as const satisfies CmdAddGlogin;
		Gupport.send(cmd, (error, msg) => {
			if (!error && msg?.payload.status === "ok") {
				setOpenInviteDialog(false);
				setSelectedItem(null);
				setOpenErrorDialog(false);
			} else {
				setOpenInviteDialog(false);
				setSelectedItem(null);
				setOpenErrorDialog(true);
			}
			setInvitingUser(false);
		});
	};

	return (
		<div style={{ display: "inline-block" }}>
			<IconButton onClick={showInviteDialog}>
				<icons.PersonAddIcon style={{ display: "block" }} htmlColor={theme.palette.primary.main} />
			</IconButton>
			<Dialog
				fullWidth={true}
				maxWidth="md"
				open={openInviteDialog}
				onClose={handleDialogClose}
			>
				<DialogTitle>{t("supportUser.inviteDialogTitle")}</DialogTitle>
				<DialogContent>
					<DialogContentText>{t("supportUser.usernameMsg")}</DialogContentText>
					<Autocomplete
						id="autocomplete_user_invite"
						fullWidth={true}
						color="inherit"
						loading={optionsLoading}
						options={supportUsers}
						filterOptions={(x) => (x)}
						isOptionEqualToValue={(option, value) => (
							Boolean(option.label && value.label) && option.label === value.label
						)}
						value={selectedItem}
						onChange={(event, value) => (setSelectedItem(value))}
						onInputChange={handleInputChange}
						renderInput={(params) => (
							<TextField autoFocus={true} {...params} placeholder="Search New-User" />
						)}
						renderOption={(props, option/*, state*/) => (
							<li {...props}><ListItemText primary={option.primary} secondary={option.secondary} /></li>
						)}
					/>
				</DialogContent>
				<DialogActions>
					<Button
						loading={invitingUser}
						disabled={selectedItem === null}
						onClick={handleInviteUserClick}
					>
						{t("supportUser.addUser")}
					</Button>
					<Button color="inherit" onClick={handleDialogClose}>{t("dialog.cancel")}</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				fullWidth={true}
				open={openErrorDialog}
				onClose={handleDialogClose}
			>
				<DialogTitle>{t("supportUser.inviteDialogTitle")}</DialogTitle>
				<DialogContent>
					<DialogContentText>
						{selectedItem?.user.supUserId ?? ""} {t("supportUser.addUserError")}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleDialogClose}>{t("dialog.ok")}</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default SupportUserInvite;
