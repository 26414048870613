import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
// hooks
import useThemeMode from "./scripts/hooks/useThemeMode";
// services
import { getMuiTheme } from "@local/theme";
// types
import type { ReactElement } from "react";

type Props = Readonly<{
	children: ReactElement;
}>;

const MuiThemeProvider = ({ children }: Props) => {
	const themeMode = useThemeMode();

	return (
		<ThemeProvider theme={getMuiTheme(themeMode)}>
			<CssBaseline enableColorScheme={true} />
			{children}
		</ThemeProvider>
	);
};

export default MuiThemeProvider;
