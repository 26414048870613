import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
// cmps
import PageContainer from "./PageContainer";
import CenterCircularProgress from "./scripts/cmp/CenterCircularProgress";
// services
import { checkIsAuthenticated } from "./scripts/services/authenticator";
import User from "./scripts/services/user";
import Gupport from "./scripts/services/gupport";

type Props = Readonly<{
	externalPage?: boolean;
}>;

const RouteListener = ({ externalPage = false }: Props) => {
	const navigate = useNavigate();

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		checkIsAuthenticated().then(async (isAuthenticated) => {
			if (isAuthenticated) {
				await Gupport.connect();
			} else {
				await User.logoutCleanup();
				// TODO: check async navigate
				void navigate("/login");
			}
		}).catch((error) => {
			console.error("Failed to check if authenticated", error);
		}).finally(() => {
			setLoading(false);
		});
	}, [navigate]);

	if (loading) {
		return <CenterCircularProgress center={true} />;
	}

	return <PageContainer externalPage={externalPage} />;
};

export default RouteListener;
