import { Component } from "react";
import { TextField, ListItemText, ListItemIcon, Autocomplete } from "@mui/material";
import Svg from "../svg";
// services
import Gupport from "../../services/gupport";
import { sortAlphabetically } from "../../services/l10n";
// types
import type { UserId } from "../../types/user";
import type { CmdGetGlogins } from "../../types/CmdGetGlogins";

type Props = Readonly<{
	userId: UserId;
	onSelectedUserChanged: (value: UserId) => void;
	onClear: () => void;
}>;

type State = {
	searchText: string;
	optionsLoading: boolean;
	options: any;
};

class GloginSelect extends Component<Props, State> {

	public static defaultProps = {
		userId: null,
	};

	constructor(props: Props) {
		super(props);

		this.state = {
			searchText: props.userId,
			optionsLoading: false,
			options: [],
		};

		this.handleChange = this.handleChange.bind(this);
	}

	override componentDidMount() {
		this.fetchGlogins();
	}

	override componentDidUpdate(prevProps) {
		if (this.props.userId !== prevProps.userId) {
			this.setState({
				searchText: this.props.userId,
			});
		}
	}

	fetchGlogins() {
		if (Gupport.getGlogins) {
			this.setState({
				optionsLoading: true,
			});

			const cmd = {
				action: "getGlogins",
			} as const satisfies CmdGetGlogins;
			Gupport.send(cmd, (error, msg) => {
				if (!error && msg?.payload.status === "ok") {
					this.setState({
						optionsLoading: false,
						options: GloginSelect.prepareGloginsData(msg.payload.data),
					});
				} else {
					this.setState({
						optionsLoading: false,
						options: [],
					});
				}
			});
		}
	}

	handleChange(_event, value) {
		if (value === null) {
			this.setState({
				searchText: "",
				options: [],
			});
			this.props.onClear();
		} else {
			this.setState({
				searchText: value.label,
			});
			this.props.onSelectedUserChanged(value);
		}
	}

	static prepareGloginsData(data) {
		const options = data.map((item) => ({
			id: item.id,
			label: `${item.data.display_name} (${item.data.user_name}) [${item.data.user_id}]`,
			search: [item.data.user_id, item.data.user_name, item.data.display_name],
			primary: `${item.data.display_name} (${item.data.user_name})`,
			secondary: item.data.user_id,
		}));

		options.sort((optionA, optionB) => (
			sortAlphabetically(optionA.label.toLowerCase(), optionB.label.toLowerCase())
		));

		return options;
	}

	override render() {
		return (
			<div style={{ position: "relative" }}>
				<Autocomplete
					id="autocomplete_glogin"
					fullWidth={true}
					loading={this.state.optionsLoading}
					options={this.state.options}
					filterOptions={(options, state) => {
						if (state.inputValue === "") {
							return options;
						}

						const search = state.inputValue.toLowerCase();
						return options.filter((option) => (
							option.search.some((value) => (value.toLowerCase().includes(search)))
						));
					}}
					isOptionEqualToValue={(option, value) => (option.label === value)}
					value={this.state.options.find((option) => (option.id === this.state.searchText))?.label ?? this.state.searchText}
					onChange={this.handleChange}
					renderInput={(params) => (
						<TextField autoFocus={true} {...params} placeholder="Search Support-User" />
					)}
					renderOption={(props, option/*, state*/) => (
						<li {...props}>
							<ListItemIcon><Svg src="add/add-user.svg" /></ListItemIcon>
							<ListItemText primary={option.primary} secondary={option.secondary} />
						</li>
					)}
					style={{ paddingRight: "24px" }}
				/>
			</div>
		);
	}

}

export default GloginSelect;
