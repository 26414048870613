import { Component } from "react";
import { withTranslation } from "react-i18next";
import { Drawer, Divider, IconButton, List, ListItem, ListItemText, Skeleton } from "@mui/material";
// cmp
import NavigationLogo from "./navigation-logo";
import NavigationDrawerMenu from "./navigation-drawer-menu";
import ConnectionFailedDialog from "./connection-failed-dialog";
import Svg from "../svg";
// services
import User from "../../services/user";
// types
import type { ReadonlyDeep } from "type-fest";
import type { WithTranslation } from "react-i18next";
import type { LoginUserGupport } from "../../types/gupport";

type Props = Readonly<WithTranslation & {
	open: boolean;
	docked: boolean;
	activeRoute: string;
	width?: string | number;
	onOpenChanged?: ((open: boolean) => void) | null;
}>;

type State = {
	user: ReadonlyDeep<LoginUserGupport> | undefined;
	open: boolean;
};

class NavigationDrawer extends Component<Props, State> {

	public static defaultProps = {
		width: 300,
		onOpenChanged: null,
	} satisfies Partial<Props>;

	constructor(props: Props) {
		super(props);

		this.state = {
			user: User.user,
			open: props.open,
		};

		this.handleUserChanged = this.handleUserChanged.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleExpertModeChanged = this.handleExpertModeChanged.bind(this);
		this.handleLogout = this.handleLogout.bind(this);
	}

	override componentDidMount() {
		User.on("changed", this.handleUserChanged);
	}

	override componentDidUpdate(prevProps: Props) {
		if (this.props.open !== prevProps.open) {
			this.setState({
				open: this.props.open,
			});
		}
	}

	override componentWillUnmount() {
		User.off("changed", this.handleUserChanged);
	}

	handleUserChanged(userData) {
		this.setState({
			user: userData.user,
		});
	}

	handleClose() {
		this.setState({
			open: false,
		}, () => {
			if (this.props.onOpenChanged) {
				this.props.onOpenChanged(this.state.open);
			}
		});
	}

	handleExpertModeChanged() {
		this.forceUpdate();
	}

	async handleLogout() {
		await User.logout();
	}

	override render() {
		const { t, open, docked, activeRoute, width } = this.props;

		return (
			<Drawer
				variant={docked ? "persistent" : "temporary"}
				open={open}
				onClose={this.handleClose}
				slotProps={{
					paper: {
						component: "nav",
						style: {
							width: width,
						},
					},
				}}
			>
				<div>{/* div needed here */}
					<ConnectionFailedDialog />
					<NavigationLogo onExpertModeChanged={this.handleExpertModeChanged} />
					<Divider />
					<List disablePadding={true}>
						<ListItem secondaryAction={<IconButton title={t("menu.logout")} edge="end" onClick={this.handleLogout}><Svg src="navigation/logout.svg" /></IconButton>}>
							<ListItemText
								primary={this.state.user?.user_name ?? <Skeleton />}
								secondary={this.state.user?.username ?? <Skeleton />}
								slotProps={{
									primary: {
										noWrap: true,
									},
									secondary: {
										noWrap: true,
									},
								}}
							/>
						</ListItem>
						<Divider />
						<NavigationDrawerMenu activeRoute={activeRoute} />
					</List>
				</div>
			</Drawer>
		);
	}

}

export default withTranslation()(NavigationDrawer);
